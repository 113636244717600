
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumbs/breadcrumb";
import { themeName } from "@/core/helpers/documentation";

export default defineComponent({
  name: "overview",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Overview");
    });

    return {
      themeName
    };
  }
});
